import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
// Components
import Container from '../../layouts/Container';
// Styled
import styled from 'styled-components';
// UI
import H1 from '../../UI/Titles/H1';

import {
  Wrapper,
  Content,
  Head4,
  P,
  Btn,
} from './styled';

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const CategoryBanner = ({ className, data: {
  title, subtitle, text, button, background
} }) => {

    const image = getImage(background?.localFile?.childImageSharp?.gatsbyImageData)
    const bgimage = convertToBgImage(image);

  return (
    <Wrapper>
      <Container>
        <BackgroundImage
          Tag="section" 
          className={className}
          alt={title}
          {...bgimage}
        >
          <Content>
            <H1 value={title} />
            {subtitle ? <Head4 value={subtitle} /> : null}
            {text ? <P>{text}</P> : null}
            <Btn value={button.text} href={button.href} sm="true" />
          </Content>
        </BackgroundImage>
      </Container>
    </Wrapper>
  );
};

CategoryBanner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
    background: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const Banner = styled(CategoryBanner)`
  border-radius: 25px;
  overflow: hidden;
`;

export default Banner;
