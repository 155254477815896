import React from 'react';
import PropTypes from 'prop-types';
// Components
import Container from '../../layouts/Container';
// UI
import H1 from '../../UI/Titles/H1';
// Utils
import { useIsTablet } from '../../../utils/responsiveHelper';
// Style
import {
  Wrapper,
  Content,
  Head4,
  P,
  Btn,
} from './styled';

//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"


const CategoryBanner = ({ className, data: {
  banner: {
    title, subtitle, text, button, background
  },
  categoryBgSm,
} }) => {
  const isTablet = useIsTablet();

  const image = getImage(background?.localFile?.childImageSharp?.gatsbyImageData);
  const bgImage = convertToBgImage(image);

  const mobileimage = getImage(categoryBgSm?.childImageSharp?.gatsbyImageData);
  const bgmobileimage = convertToBgImage(mobileimage);

  const chosenBG = !isTablet ? bgImage : bgmobileimage

  return (
    <Wrapper
      Tag="section" 
      className={className}
      {...chosenBG}
    >
      <Container>
        <Content>
          <H1 value={title} />
          <Head4 value={subtitle} />
          <P>{text}</P>
          <Btn value={button.text} href={button.href} sm="true" />
        </Content>
      </Container>
    </Wrapper>
  );
};

CategoryBanner.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    button: PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
    background: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default CategoryBanner;
