import ReactMarkdown from 'react-markdown';
// Styled
import styled from 'styled-components';
import Fonts from '../../../assets/styles/Fonts';
import Colors from '../../../assets/styles/Colors';
import Typography from '../../../assets/styles/Typography';
import { tablet } from "../../../assets/styles/Responsive";
// UI
import H4 from '../../UI/Titles/H4';
import Button from '../../UI/Button';

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 70px;
  background-color: ${Colors.gin};
`;

const Content = styled.div`
  padding: 130px 15px;
  max-width: 840px;
  margin: 0 auto;
  width: 100%;
  text-align: center;

  @media (max-width: ${tablet}) {
    padding: 90px 15px 80px;
  }
`;

const Head4 = styled(H4)`
  margin-top: 50px;
  text-transform: none;
  font-family: ${Fonts.IBMBold};

  @media (max-width: ${tablet}) {
    margin-top: 45px;
  }
`;

const P = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 50px;
  text-align: center;

  @media (max-width: ${tablet}) {
    margin-top: 45px;
  }
`;

const Btn = styled(Button)`
  margin-top: 50px;

  @media (max-width: ${tablet}) {
    margin-top: 45px;
  }
`;

export {
  Wrapper,
  Content,
  Head4,
  P,
  Btn,
}