import ReactMarkdown from 'react-markdown';
// Styled
import styled from 'styled-components';
import Colors from '../../../../../assets/styles/Colors';
import Typography from '../../../../../assets/styles/Typography';
import { tablet } from "../../../../../assets/styles/Responsive";
// UI
import H1 from '../../../../UI/Titles/H1';
import Button from '../../../../UI/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isreverse ? 'row-reverse' : 'row'};
  padding-top: 125px;
  
  &:first-child {
    padding-top: 110px;
  }

  @media (max-width: ${tablet}) {
    padding-top: 120px;
    display: block;

    &:first-child {
      padding-top: 65px;
    }
  }
`;

const Description = styled.div`
  flex-basis: 100%;
  padding: ${props => props.isreverse ? '0 0 0 115px' : '0 115px 0 0'};

  @media (max-width: ${tablet}) {
    padding: 0;
    text-align: center;
  }
`;

const Head1 = styled(H1)`
  color: ${Colors.green};

  @media (max-width: ${tablet}) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const P = styled(ReactMarkdown)`
  ${Typography.richText};

  @media (max-width: ${tablet}) {
    margin-top: 25px;
  }
`;

const Btn = styled(Button)`
  margin-top: 60px;

  @media (max-width: ${tablet}) {
    margin-top: 35px;
  }
`;

const Media = styled.div`
  max-width: 565px;
  width: 100%;
  flex-shrink: 0;

  @media (max-width: ${tablet}) {
    margin: 35px auto 0;
    text-align: center;
  }
`;

const MediaCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 15px;
  border-radius: 25px;
  background-color: ${Colors.shadowGreen};
`;

export {
  Wrapper,
  Description,
  Head1,
  P,
  Btn,
  Media,
  MediaCard,
}