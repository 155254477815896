import React from 'react';
import PropTypes from 'prop-types';
// Components
import Container from '../../layouts/Container';
import CategoryItem from './components/CategoryItem';
// Styled
import styled from 'styled-components';
import Colors from '../../../assets/styles/Colors';

const Wrapper = styled.div`
  padding-bottom: 80px;
  background-color: ${Colors.gin};
`;

const Category = ({ data }) => {
  return (
    <Wrapper>
      <Container>
        {data.map((category, index) => <CategoryItem data={category} key={category.id} isreverse={(index % 2) !== 0} /> )}
      </Container>
    </Wrapper>
  );
};

Category.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Category;
