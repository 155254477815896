import ReactMarkdown from 'react-markdown';
import BackgroundImage from 'gatsby-background-image';
// Styled
import styled from 'styled-components';
import Fonts from '../../../assets/styles/Fonts';
import { tablet, mobile } from "../../../assets/styles/Responsive";
// UI
import H4 from '../../UI/Titles/H4';
import Button from '../../UI/Button';
import Typography from '../../../assets/styles/Typography';
import Colors from '../../../assets/styles/Colors';

const Wrapper = styled(BackgroundImage)`
  padding: 175px 0 160px;
  background-color: ${Colors.shadowGreen};
  background-size: cover;
  background-position: right bottom;

  @media (max-width: ${tablet}) {
    padding: 120px 0 540px;
    background-position: center bottom;
  }
`;

const Content = styled.div`
  max-width: 700px;
  width: 100%;

  @media (max-width: ${tablet}) {
    max-width: 500px;
    text-align: center;
  }

  @media (max-width: ${mobile}) {
    margin: 0 auto;
    max-width: 100%;
  }
`;

const Head4 = styled(H4)`
  margin-top: 65px;
  text-transform: none;
  font-family: ${Fonts.IBMBold};

  @media (max-width: ${tablet}) {
    margin-top: 25px;
  }
`;

const P = styled(ReactMarkdown)`
  ${Typography.richText};
  margin-top: 30px;

  @media (max-width: ${tablet}) {
    margin-top: 25px;
  }
`;

const Btn = styled(Button)`
  margin-top: 65px;

  @media (max-width: ${tablet}) {
    margin-top: 30px;
  }
`;

export {
  Wrapper,
  Content,
  Head4,
  P,
  Btn,
}