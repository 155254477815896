import React from "react";
import PropTypes from 'prop-types';
// Layout
import MainLayout from "../../layouts/MainLayout";
// Components
import Seo from "../Seo/seo";
import CategoryBanner from '../CategoryBanner';
import Category from '../Category';
import CenterBanner from '../CenterBanner';

const CategoriesPage = ({ pageContext: { data: { banner, accessory, torontoBanner, categoryBgSm } } }) => {
  return (
    <MainLayout>
      <Seo
        title={banner.title}
      />
      <CategoryBanner data={{ banner, categoryBgSm }} />
      {accessory ? <Category data={accessory} /> : null}
      {torontoBanner ? <CenterBanner data={torontoBanner} /> : null}
    </MainLayout>
  );
};

CategoriesPage.propTypes = {
  pageContext: PropTypes.shape({
    data: PropTypes.shape({}).isRequired,
  }).isRequired,
}

export default CategoriesPage;
