import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
// Utils
import { useIsTablet } from '../../../../../utils/responsiveHelper';
// Styled
import {
  Wrapper,
  Description,
  Head1,
  P,
  Btn,
  Media,
  MediaCard,
} from './styled';

const CategoryItem = ({ data: {
  title, text, button, image,
}, isreverse }) => {
  const isTablet = useIsTablet();
  return (
    <Wrapper isreverse={isreverse}>
      <Description isreverse={isreverse}>
        <Head1 value={title} />
        <P>{text}</P>
        {!isTablet ? <Btn value={button.text} href={button.href} sm="true" /> : null}
      </Description>
      <Media>
        <MediaCard>
          <GatsbyImage image={image?.localFile?.childImageSharp?.gatsbyImageData} alt={image?.name} />
        </MediaCard>
        {isTablet ? <Btn value={button.text} href={button.href} sm="true" /> : null}
      </Media>
    </Wrapper>
  );
};

CategoryItem.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default CategoryItem;
